















































































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";

import CounterpartyForm from "@/accounting-portal/components/counterparties/CounterpartyForm.vue";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";

import CounterpartyResponseModel from "@/accounting-portal/models/counterparties/CounterpartyResponseModel";
import BankResponseModel from "@/accounting-portal/models/banks/BankResponseModel";
import CounterpartyNetworkResponseModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    CounterpartyForm,
    ConfirmationDialog,
  },
})
export default class CounterpartiesView extends mixins(AppSectionAccessMixin) {
  readonly EXPANDED_HEADERS = [
    {
      text: "",
      width: "100px",
      sortable: false,
    },
    {
      text: this.$lang("accountingPortal.counterparties.networks.name"),
      value: "name",
    },
    {
      text: this.$lang("accountingPortal.counterparties.networks.dateFrom"),
      value: "dateFrom",
    },
    {
      text: this.$lang("accountingPortal.counterparties.networks.dateTo"),
      value: "dateTo",
    },
  ];
  search = "";
  isSideBarVisible = false;
  isDeleteDialogVisible = false;
  deletingCounterpartyId: number | null = null;
  editingItem: CounterpartyResponseModel | null = null;
  expanded: Array<CounterpartyResponseModel> = [];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get headers(): Array<Record<string, any>> {
    return [
      {
        value: "data-table-expand",
      },
      {
        text: this.$lang("accountingPortal.counterparties.name"),
        value: "name",
      },
      {
        text: this.$lang("accountingPortal.counterparties.currencyCode"),
        value: "currencyCode",
      },
      {
        text: this.$lang("accountingPortal.counterparties.currentBank"),
        value: "currentBankName",
      },
      {
        text: this.$lang("accountingPortal.counterparties.taxation.title"),
        value: "taxation",
      },
      {
        text: this.$lang("accountingPortal.counterparties.note"),
        value: "note",
        width: "20%",
      },
      ...(this.hasDeleteAccess || this.hasEditAccess
        ? [
            {
              text: this.$lang("shared.actions"),
              align: "end",
              value: "actions",
              width: "10%",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartiesLoading;
  }

  get isCounterpartyDeleting(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartyDeleting;
  }

  get counterparties(): Array<CounterpartyResponseModel> {
    return this.$store.state.counterpartyStore.counterparties;
  }

  @Watch("isSideBarVisible")
  watchIsSideBarVisible(value: boolean) {
    if (!value && this.editingItem) {
      this.editingItem = null;
    }

    if (value) {
      this.$store.dispatch("loadBanks");
    }
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("accountingPortal.counterparties.title")
    );

    this.$store.dispatch("loadCounterparties");
  }

  destroyed() {
    this.$store.commit("clearCounterparties");
  }

  canExpand(item: CounterpartyResponseModel): boolean {
    return !!item.currentNetworks.length;
  }

  showCounterpartyFormSideBar(item?: CounterpartyResponseModel) {
    if (item) {
      this.editingItem = CounterpartyResponseModel.of(cloneDeep(item));
    }

    this.isSideBarVisible = true;
  }

  handleClickByTableRow(item: CounterpartyResponseModel) {
    this.$router.push({
      name: "counterparty",
      params: {
        counterpartyId: String(item.id),
      },
    });
  }

  showDeleteDialog({ id }: BankResponseModel) {
    this.deletingCounterpartyId = id;
    this.isDeleteDialogVisible = true;
  }

  async deleteCounterparty() {
    await this.$store.dispatch(
      "deleteCounterparty",
      this.deletingCounterpartyId
    );
    this.deletingCounterpartyId = null;
    this.isDeleteDialogVisible = false;
  }

  handleClickByExpandedTableRow(item: CounterpartyNetworkResponseModel) {
    this.$router.push({
      name: "counterpartyNetworks",
      params: {
        counterpartyId: String(item.counterpartyId),
      },
    });
  }
}
